import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import VueMeta from 'vue-meta';
import axios from 'axios';
import store from './../store';

import Dashboard from '../views/Dashboard.vue';
import AdaptLogin from '../views/AdaptLogin.vue';
import ProjectSelection from '../components/projects/ProjectSelection.vue';
import Users from '../components/users/Users.vue';
import eTracker from '../components/eTracker/eTracker.vue';
import eTrackerAddProject from '../components/eTracker/eTrackerAddProject.vue';
// import UserList from '../components/users/UserList.vue';
import AddUser from '../components/users/AddUser.vue';
import SelectedProjects from '../components/projects/SelectedProjects';
import DataSources from '../components/DataSetDefinition/DataSources';
import DataSetList from '../components/DataSetDefinition/DataSetList';
import AddDataSet from '../components/DataSetDefinition/AddDataSet';
import SelectDataSources from '../components/DataSetDefinition/SelectDataSources';
import DataSetColumnsNew from '../components/DataSetDefinition/DataSetEdit/DataSetColumnsNew';
import TableSelectTool from '../components/projects/TableSelectTool';
import TitleBlock from '../components/projects/TitleBlock';
import DataPostingTool from '../components/projects/DataPostingTool';
import FilterOptions from '../components/projects/FilterOptions';
import ProjectList from '../components/projects/manage/ProjectList';
import ManageDataSource from '../components/DataSource/ManageDataSource';
import PortalSettings from '../components/users/PortalSettings';
import scatterPlot from '../components/projects/scatterPlot';
import UserRightManagement from '../components/AccessManagement/UserRightManagement';
import DirectoryUpload from '../components/projects/DirectoryUpload';
import PowerBI from '../components/PowerBI/PowerBI';
import OneMap from '../views/Frame.vue';
import iFrame from '../views/iFrame.vue';

//classic

import ClassicHome from '../classic/views/Home.vue';
import ClassicProjectSelection from '../classic/components/projects/ProjectSelection.vue';
import ClassicMapView from '../classic/components/projects/MapView.vue';
import ClassicListView from '../classic/components/projects/ListView.vue';
import ClassicSelectedProjects from '../classic/components/projects/SelectedProjects';
import ClassicTableSelectTool from '../classic/components/projects/TableSelectTool';
import ClassicTitleBlock from '../classic/components/projects/TitleBlock';
import ClassicDataPostingTool from '../classic/components/projects/DataPostingTool';
import ClassicFilterOptions from '../classic/components/projects/FilterOptions';
import ClassicProjectList from '../classic/components/projects/manage/ProjectList';
import ClassicscatterPlot from '../classic/components/projects/scatterPlot';
import ClassicDirectoryUpload from '../classic/components/projects/DirectoryUpload';
import ClassicEMMainMap from '../classic/views/EMMainMap.vue';
import ClassicEMMobileMap from '../classic/views/EMMobileMap.vue';
import ClassicPowerBI from '../classic/components/PowerBI/PowerBI';

//AppInsight
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { projectService } from '../services';

const clickPluginInstance = new ClickAnalyticsPlugin();
// Click Analytics configuration
const clickPluginConfig = {
    autoCapture: true,
};
// Application Insights Configuration
const configObj = {
    connectionString: process.env.VUE_APP_INSIGHT_KEY,
    // Alternatively, you can pass in the instrumentation key,
    // but support for instrumentation key ingestion will end on March 31, 2025.
    // instrumentationKey: "YOUR INSTRUMENTATION KEY",
    extensions: [clickPluginInstance],
    extensionConfig: {
        [clickPluginInstance.identifier]: clickPluginConfig,
    },
};

const appInsights = new ApplicationInsights({ config: configObj });
appInsights.loadAppInsights();
appInsights.trackPageView();

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/login',
        name: 'Home',
        component: Home,
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
    },
    {
        path: '/adaptlogin',
        name: 'AdaptlLgin',
        component: AdaptLogin,
    },
    {
        path: '/map',
        name: 'Map',
        component: OneMap,
    },
    {
        path: '/digital',
        name: 'digital',
        component: iFrame,
        children: [
            {
                path: '/digital/InSite',
                name: 'InSite',
            },
            {
                path: '/digital/InSite/SiteCode/:id',
                name: 'InSiteSiteCode',
                component: iFrame,
            },
        ],
    },
    {
        path: '/digital',
        name: 'digital',
        component: iFrame,
        children: [
            {
                path: '/digital/InSiteDev',
                name: 'InSiteDev',
            },
            {
                path: '/digital/InSiteDev/SiteCode/:id',
                name: 'InSiteDevSiteCode',
                component: iFrame,
            },
        ],
    },
    {
        path: '/digital',
        name: 'digital',
        component: iFrame,
        children: [
            {
                path: '/digital/MapDigitize',
                name: 'MapDigitize',
            },
        ],
    },
    {
        path: '/project',
        name: 'Project',
        component: ProjectSelection,
        children: [
            {
                path: '/project/selectedprojects',
                name: 'selectedprojects',
                component: SelectedProjects,
            },
            {
                path: '/project/selectedprojects/manageattributes',
                name: 'manageattributes',
                component: SelectedProjects,
            },
            {
                path: '/project/selectedprojects/browsefiles/:id',
                name: 'browsefiles',
                component: SelectedProjects,
            },
            {
                path: '/project/selectedprojects/directoryupload',
                name: 'DirectoryUpload',
                component: SelectedProjects,
            },
            {
                path: '/project/selectedprojects/documentupload',
                name: 'documentupload',
                component: SelectedProjects,
            },
        ],
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
    },
    {
        path: '/adduser',
        name: 'adduser',
        component: AddUser,
    },
    {
        path: '/edituser/:id',
        name: 'edituser',
        component: AddUser,
    },
    {
        path: '/userrightmanagement',
        name: 'userrightmanagement',
        component: UserRightManagement,
    },
    {
        path: '/eTracker',
        name: 'eTracker',
        component: eTracker,
    },
    {
        path: '/eTrackerAddProject',
        name: 'eTrackerAddProject',
        component: eTrackerAddProject,
    },
    {
        path: '/editeTrackerProject/:id',
        name: 'editeTrackerProject',
        component: eTrackerAddProject,
    },
    {
        path: '/tableselectiontool',
        name: 'tableselectiontool',
        component: TableSelectTool,
    },
    {
        path: '/tableselectiontool/LoadHistory/:args/:isSavedWork',
        name: 'tableselectiontoolLoadHistory',
        component: TableSelectTool,
    },
    {
        path: '/tableselectiontool/datasetexport:id',
        name: 'datasetexport',
        component: TableSelectTool,
    },
    {
        path: '/tableselectiontool/TitleBlock/:id',
        name: 'TitleBlock',
        component: TableSelectTool,
    },
    {
        path: '/tableselectiontool/datapostingtools/:id/:tool',
        name: 'datapostingtools',
        component: TableSelectTool,
    },
    {
        path: '/tableselectiontool',
        name: 'chartelement',
        component: TableSelectTool,
    },
    {
        path: '/portalsetting',
        name: 'portalsetting',
        component: PortalSettings,
    },
    {
        path: '/titleblock/:id',
        name: 'titleblock',
        component: TitleBlock,
    },
    {
        path: '/datapostingtool',
        name: 'datapostingtool',
        component: DataPostingTool,
    },
    {
        path: '/filteroptions',
        name: 'filteroptions',
        component: FilterOptions,
    },
    {
        path: '/scatterPlot',
        name: 'scatterplot',
        component: scatterPlot,
    },
    {
        path: '/datasetdefinition',
        name: 'datasetdefinition',
        component: DataSources,
        children: [
            {
                path: '/datasetdefinition/selectdatasources',
                name: 'selectdatasources',
                component: SelectDataSources,
            },
            {
                path: '/datasetdefinition/datasetlist/:id/:sourceName',
                name: 'datasetlist',
                component: DataSetList,
            },
            {
                path: '/datasetdefinition/adddataset/:id/:sourceName',
                name: 'adddataset',
                component: AddDataSet,
            },
            {
                path:
                    '/datasetdefinition/edit/datasetcolumns/:id/:tablename?/:tableAliasName/:datasetId?/:type?/:reportId?/:sourceName',
                name: 'datasetcolumnsNew',
                component: DataSetColumnsNew,
            },
        ],
    },
    {
        path: '/projectlist',
        name: 'Project List',
        component: ProjectList,
    },
    {
        path: '/managedatasource',
        name: 'Datasource List',
        component: ManageDataSource,
    },
    {
        path: '/DirectoryUpload',
        name: 'DirectoryUpload',
        component: DirectoryUpload,
    },
    {
        path: '/PowerBI',
        name: 'PowerBI',
        component: PowerBI,
    },

    //classic
    {
        path: '/classic',
        name: 'ClassicHome',
        component: ClassicHome,
    },
    {
        path: '/classic/login',
        name: 'ClassicHome',
        component: ClassicHome,
    },
    {
        path: '/classic/MainMap',
        name: 'ClassicEMMainMap',
        component: ClassicEMMainMap,
    },
    {
        path: '/classic/MobileMap',
        name: 'ClassicEMMobileMap',
        component: ClassicEMMobileMap,
    },
    {
        path: '/classic/project',
        name: 'ClassicProject',
        component: ClassicProjectSelection,
        children: [
            {
                path: '/classic/project/mapview',
                name: 'Classicmap',
                component: ClassicMapView,
            },
            {
                path: '/classic/project/listview',
                name: 'Classiclist',
                component: ClassicListView,
            },
            {
                path: '/classic/project/selectedprojects',
                name: 'ClassicSelectedProjects',
                component: ClassicSelectedProjects,
            },
            {
                path: '/classic/project/selectedprojects/manageattributes',
                name: 'Classicmanageattributes',
                component: ClassicSelectedProjects,
            },
            // {
            //   path: "/project/selectedprojects/manageattributes:currentSwitchState",
            //   name: "manageattributes",
            //   component: ManageAttributes
            // },
            {
                path: '/classic/project/selectedprojects/browsefiles/:id',
                name: 'Classicbrowsefiles',
                component: ClassicSelectedProjects,
            },
            {
                path: '/classic/project/selectedprojects/directoryupload',
                name: 'ClassicDirectoryUpload',
                component: ClassicDirectoryUpload,
            },
            {
                path: '/classic/project/selectedprojects/documentupload',
                name: 'Classicdocumentupload',
                component: ClassicSelectedProjects,
            },
        ],
    },
    {
        path: '/classic/tableselectiontool',
        name: 'Classictableselectiontool',
        component: ClassicTableSelectTool,
    },
    {
        path: '/classic/tableselectiontool/LoadHistory/:args/:isSavedWork',
        name: 'ClassictableselectiontoolLoadHistory',
        component: ClassicTableSelectTool,
    },
    {
        path: '/classic/tableselectiontool/datasetexport:id',
        name: 'Classicdatasetexport',
        component: ClassicTableSelectTool,
    },
    {
        path: '/classic/tableselectiontool/TitleBlock/:id',
        name: 'ClassicTitleBlock',
        component: ClassicTableSelectTool,
    },
    {
        path: '/classic/tableselectiontool/datapostingtools/:id/:tool',
        name: 'Classicdatapostingtools',
        component: ClassicTableSelectTool,
    },
    {
        path: '/classic/tableselectiontool',
        name: 'Classicchartelement',
        component: ClassicTableSelectTool,
    },
    {
        path: '/classic/titleblock/:id',
        name: 'Classictitleblock',
        component: ClassicTitleBlock,
    },
    {
        path: '/classic/datapostingtool',
        name: 'Classicdatapostingtool',
        component: ClassicDataPostingTool,
    },
    {
        path: '/classic/filteroptions',
        name: 'Classicfilteroptions',
        component: ClassicFilterOptions,
    },
    {
        path: '/classic/scatterPlot',
        name: 'Classicscatterplot',
        component: ClassicscatterPlot,
    },
    {
        path: '/classic/projectlist',
        name: 'ClassicProject List',
        component: ClassicProjectList,
    },
    {
        path: '/classic/DirectoryUpload',
        name: 'ClassicDirectoryUpload',
        component: ClassicDirectoryUpload,
    },
    {
        path: '/classic/PowerBI',
        name: 'ClassicPowerBI',
        component: ClassicPowerBI,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2)
        return parts
            .pop()
            .split(';')
            .shift();
}
router.beforeEach((to, from, next) => {
    const publicPages = ['/', '/classic/login', '/adaptlogin'];
    const externalTools = ['/digital/InSite', '/digital/InSiteDev'];
    const externalNameTools = ['InSiteSiteCode', 'InSiteDevSiteCode'];
    const accessToken = getCookie('accessToken');
    if (!accessToken) {
        if (publicPages.includes(to.path)) {
            next();
        } else {
            // if not logged in, save the page to direct to after login
            store.commit('store/setGoToPage', to.fullPath);
            next({
                path: '/',
            });
        }
        delete axios.defaults.headers.common['Authorization'];
    } else {
        axios.defaults.headers.common[
            'Authorization'
        ] = `Bearer ${accessToken}`;
        if (publicPages.includes(to.path)) {
            next({
                path: '/dashboard',
            });
        } else {
            next();
        }
    }

    if (
        to.path == '/classic/project/selectedprojects' &&
        from.path == '/classic/project/mapview'
    ) {
        localStorage.setItem('getProjectLayersFromApi', true);
    } else {
        localStorage.setItem('getProjectLayersFromApi', false);
    }

    if (to.path == '/map' && externalNameTools.includes(from.name)) {
        store.commit('store/setGoToPage', from.fullPath);
        localStorage.setItem('isExternalPath', true);
    }

    if (to.path == '/map' && to.query['projectID']) {
        projectService.CanAccessProject(to.query['projectID']).then((res) => {
            if (res.data) {
                next();
            } else {
                // clear the query so our project select logic doesn't trigger
                next({ path: '/map', query: [] });
            }
        });
    }
});
export default router;
